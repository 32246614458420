import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Qrcode.css';

const POC = ({ randomId }) => {

  const [invoiceData, setInvoiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const response = await axios.get('https://api.khatatracker.com/salerandom/e3TBAl');
        setInvoiceData(response.data.saleInvoice);
        console.log('response',response)
      } catch (err) {
        setError('Error fetching invoice data.');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoice();
  }, []);




  return (
    <div>
          {invoiceData ? (
  <div style={{ padding: '20px', backgroundColor: '#FFFFFF', maxWidth: '600px', margin: 'auto', fontFamily: 'Courier Prime' }} id='pdf-content'>
      <h2 style={{ textAlign: 'center' }} className='print'>{invoiceData.myDetails?.name || 'N/A'}</h2>
      <p style={{ textAlign: 'center' }} className='print'>Ph.No:{invoiceData.myDetails?.phoneNumber|| 'N/A'} </p>
      <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

      <div style={{ marginTop: '20px', marginLeft: "30px" }}>
        <strong className='print'>To</strong>
      
        <p className='mt-2 print'>{invoiceData.customerDetails?.name || 'N/A'}
             </p>


         <p> Phone:{invoiceData.customerDetails?.phoneNumber || 'N/A'} </p>
      </div>
      

      <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

      <h3 style={{ textAlign: 'center', margin: '20px 0' }} className='print'>SALE BILL</h3>

      <p style={{ display: 'flex', justifyContent: "center" }}>
        <span style={{ fontWeight: 'bold' }} className='print'>Invoice No:</span>&nbsp;&nbsp;<span className='print'>{invoiceData.invoiceNumber || 'N/A'}</span>
      </p>
      <p style={{ justifyContent: "center", display: 'flex' }} className='print'>
        <span style={{ fontWeight: 'bold' }}>Invoice Date:</span>&nbsp;&nbsp; <span>{invoiceData.date || 'N/A'}</span>
      </p>

      <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />
      <table className='POCtable'>
        <thead>
          <tr className='print'>
            <th>S. No.</th>
            <th>Items</th>
            <th>Quantity</th>
            <th >Tax</th>
            <th >Discount</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>

          {invoiceData.items?.map((item) => (
            <tr key={item.itemId} className="sale-bill">
              <td style={{ textAlign: 'center' }}>{item.SlNo}</td>
              <td style={{ textAlign: 'center' }}>{item.itemName}</td>
              <td style={{ textAlign: 'center' }}>
                {item.quantity} {item.quantityUnits}
              </td>
              {/* <td style={{ textAlign: 'center' }}>{item.price}</td> */}
              <td style={{ textAlign: 'center' }}>{item.tax}{item.taxunits}</td>
              <td style={{ textAlign: 'center' }}>{item.discount}{item.disunits}</td>
              <td style={{ textAlign: 'center' }}>{item.total}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className='poc_gst'>
        <p className='poc_row'>Tax: <span className="value-gap">{invoiceData.tax}{invoiceData.taxunits}</span></p>
        <p className='poc_row'>Discount: <span className="value-gap">{invoiceData.discount}{invoiceData.disunits}</span></p>



    
        {invoiceData.AdditionalCharges?.map((charge) => (
          <p key={charge._id} className="poc_row">{charge.key}<span className="value-gap">{charge.value}</span></p>
        ))}
        {invoiceData.customfields?.map((Custom) => (
          <p key={Custom._id} className="poc_row">{Custom.key}<span className="value-gap">{Custom.value}</span></p>
        ))}
        <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

        <p className='poc_row'><b>Net Amount:</b> <span className="value-gap">{invoiceData.total}</span></p>
        <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />

        {invoiceData.payments.map((payment) => (
          <div key={payment._id}>
            <p className="poc_row">Amount Received: <span className="value-gap">{payment.AmountReceived}</span></p>

          </div>
        ))}
        <p className="poc_row"><b>Balance:</b> <span className="value-gap">{invoiceData.paymentStatus === 'Unpaid' ? invoiceData.total : invoiceData.payments[invoiceData.payments.length - 1]?.BalanceAmount || '0.00'}</span></p>
      </div>
      <hr style={{ border: 'none', borderBottom: '1px dashed #000', margin: '10px 0' }} />
     </div>
  ):(

    <p>No data available</p>
  )}
    </div>
  )
};

export default POC;
