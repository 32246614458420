
import { useEffect } from "react";

const SEO = ({ title, description }) => {
  useEffect(() => {
    document.title = title;

    // Helper function to set or create meta tags
    const setMetaTag = (name, content, isProperty = false) => {
      const selector = isProperty ? `meta[property="${name}"]` : `meta[name="${name}"]`;
      let metaTag = document.querySelector(selector);
      if (metaTag) {
        metaTag.setAttribute("content", content);
      } else {
        metaTag = document.createElement("meta");
        if (isProperty) {
          metaTag.setAttribute("property", name);
        } else {
          metaTag.setAttribute("name", name);
        }
        metaTag.setAttribute("content", content);
        document.head.appendChild(metaTag);
      }
    };

    // Set standard meta tags
    setMetaTag("description", "Udhar Khata Spending Tracker is the #1 accounting app to track Personal & Business Sales & Purchases with Live Reports & Analysis to simplify Profit & Loss accounting processes and save time!");
    setMetaTag("p:domain_verify", "28d2765bf06562053581db4657daf49c");
    setMetaTag("google-site-verification", "cgV1S76Gi0Tnyb9YFThnqfaiL5wcSt8B37BGLEtCpKg");
    setMetaTag("keywords", "khata tracker, khata book, khata, book, ledger book manager, money tracker, cash tracker, credit management, credit, accounting, digital india, finance book, account book, expense manager ledger book, expense manager, household expense manager, house expenses, expense tracker, daily expenses manager, cash book, day to day expenses, monthly expense tracker, walnut expense tracker, ledger, finance tracker, expensify, expenses, money tracker");

    // Set Open Graph meta tags
    setMetaTag("og:locale", "en_US", true);
    setMetaTag("og:type", "website", true);
    setMetaTag("og:title", "Khata Tracker Simplify Your Business Accounts", true);
    setMetaTag("og:url", "https://www.khatatracker.com/", true);
    setMetaTag("og:image", "assets/images/icon.png", true);
    setMetaTag("og:description", "Khata Tracker for Business Accounts is your all-in-one solution for simplified financial management, making it easy to handle ledgers, transactions, and expenses. Whether managing cash, credit, loans, or invoices, this app streamlines every aspect of the financial process. Effortlessly track bills, record income and expenses, and maintain control over your budget. Designed for small businesses and entrepreneurs, Khata Tracker keeps your accounts organized with detailed insights and reports. Manage your business finances confidently with this powerful ledger and expense management tool.", true);

  }, [title, description]);

  return null;
};

export default SEO;
