import React, { useEffect, useState } from 'react';
import Img from '../assets/images/KT_Logo_white.png';
import '../components/nav.css';
import arrow from '../assets/images/left-arrow.gif';
import { useLocation } from 'react-router-dom';
import SEO from '../components/SEO'
const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [seoTitle, setSeoTitle] = useState("Khata Tracker - Best Digital Accounting Book for Small Businesses");
  const [seoDescription, setSeoDescription] = useState("Udhar Khata Spending Tracker is the #1 accounting app to track Personal & Business Sales & Purchases with Live Reports & Analysis to simplify Profit & Loss accounting processes and save time!");
  const sectionMap = {
    "/home": { title: "Khata Tracker is a Simplified Financial Management App", description: "Track and manage your business expenses, sales, and personal finances with Khata Tracker, a top-rated accounting and digital ledger app for small businesses." },
    '/about-us': { title: "About | Khata Tracker – Secure Ledger and Expense Management ", description: "Learn about Khata Tracker's mission, our dedication to simplifying finance management, and how we help small businesses keep clear records." },
    '/features': { title: "Features | Khata Tracker for Ledger, Expense Tracker, and More", description: "Discover Khata Tracker's robust features like expense tracking, sales and purchase management, profit analysis, and digital ledger capabilities." },
    '/gallery': { title: "Gallery | Android & iOS app - Simple, Intuitive Design", description: "View screenshots and UI previews of the Khata Tracker app, showcasing its intuitive design and easy-to-use financial tracking tools."  },
    '/faq': { title: "FAQs | Frequently Asked Questions – Digital Khata and Expense Tracking", description: "Get answers to common questions about Khata Tracker, including setup, features, and how it can help you manage finances effectively."  },
    '/download-ios-android' : { title: "Download | Available for iOS & Android – Start Managing Finances Today", description: "Download Khata Business Tracker for iOS or Android to start managing your finances easily on the go, with secure data backup and advanced reporting." },
    '/contact-us': { title: "Contact Us | Khata Business Tracker - Get Support and Assistance", description: "Reach out to Khata Tracker's support team for assistance with the app, inquiries, or feedback. We're here to help you succeed financially." },
  };
 
  useEffect(() => {
    const navbarHeight = document.querySelector('.mainmenu-area')?.offsetHeight || 0;
    const scrollToSection = (sectionId) => {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - navbarHeight, 
          behavior: 'smooth',
        });
        setActiveLink(sectionId);
      }
    };
    const pathName = window.location.pathname.substring(1);
   

    if (sectionMap[pathName]) {
      scrollToSection(sectionMap[pathName], 
        
      );
    }


    const anchors = document.querySelectorAll('a[data-section]');
    const handleAnchorClick = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('data-section');
      scrollToSection(targetId);
      const customName = e.currentTarget.getAttribute('data-name');
      window.history.pushState(null, '', `/${customName}`); 
      const seoData = sectionMap[window.location.pathname];
      if (seoData) {
        setSeoTitle(seoData.title);
        setSeoDescription(seoData.description);
      
      }
    };
    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const handleScroll = () => {
      let currentSection = '';
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
          currentSection = section.getAttribute('id');
        }
      });
      setActiveLink(currentSection);

    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    <SEO title={seoTitle} description={seoDescription} />
      <nav
        className="mainmenu-area navbar navbar-expand-lg navbar-light"
        data-spy="affix"
        data-offset-top="200"
      >
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#primary_menu"
              style={{ backgroundColor: '#fff' }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand ml-2" href="index">
              <img src={Img} alt="Logo" className="nav_img" />
            </a>
          </div>
          <div className="collapse navbar-collapse justify-content-end" id="primary_menu">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'home_page' ? 'active' : ''}`}
                  data-section="home_page"
                  data-name="home"
                  href="#home_page"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'about_page' ? 'active' : ''}`}
                  data-section="about_page"
                  data-name="about-us"
                  href="#about_page"
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'features_page' ? 'active' : ''}`}
                  data-section="features_page"
                  data-name="features"
                  href="#features_page"
                >
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'gallery_page' ? 'active' : ''}`}
                  data-section="gallery_page"
                  data-name="gallery"
                  href="#gallery_page"
                >
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'faqs_page' ? 'active' : ''}`}
                  data-section="faqs_page"
                  data-name="faq"
                  href="#faqs_page"
                >
                  FAQ's
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'download_page' ? 'active' : ''}`}
                  data-section="download_page"
                  data-name="download-ios-android"
                  href="#download_page"
                >
                  Download
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'contact_page' ? 'active' : ''}`}
                  data-section="contact_page"
                  data-name="contact-us"
                  href="#contact_page"
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeLink === 'login' ? 'active' : ''} login-button`}
                  target="_blank"
                  onClick={() => window.open('https://khatatracker.com/business/', '_blank')}
                >
                  &nbsp; Login
                  <img src={arrow} alt="gif" className="left-arrow" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;