import React from 'react';
import { Link } from 'react-router-dom';


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f8f9fa',
    },
    heading: {
        fontSize: '72px',
        marginBottom: '16px',
    },
    paragraph: {
        fontSize: '24px',
        marginBottom: '32px',
    },
    link: {
        fontSize: '18px',
        color: '#007bff',
        textDecoration: 'none',
    }
};



function Notfoundpage() { 
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>404</h1>
            <p style={styles.paragraph}>Oops! The page you're looking for doesn't exist.</p>
            <Link to="/" style={styles.link}>
                Go Back to Home
            </Link>
        </div>

    );
};



export default Notfoundpage;
