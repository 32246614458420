// // index.js
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import App from './App';
// import StandaloneManageAccount from './components/StandaloneManageAccount';
// import DeletealoneManageAccount from './components/DeletealoneManageAccount';
// import './index.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'jquery/dist/jquery.min.js';
// import 'popper.js/dist/popper.min.js';
// import 'bootstrap/dist/js/bootstrap.min.js';

// ReactDOM.render(
//   <Router>
//     <Routes>
//       <Route path="/standalone-manage-account" element={<StandaloneManageAccount />} />
//        <Route path="/delete-account" element={<DeletealoneManageAccount />} /> 

//       <Route path="/*" element={<App />} />
//     </Routes>
//   </Router>,
//   document.getElementById('root')
// );
// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import StandaloneManageAccount from './components/StandaloneManageAccount';
import DeletealoneManageAccount from './components/DeletealoneManageAccount';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import SMS_View from './SMS_View';
import CancellationRefundPolicy from './components/refund';
import DeleteAccount from './components/delete';
import POC from './Qrcode';

ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/login-delete" element={<DeleteAccount />} />
       <Route path="/delete-account" element={<StandaloneManageAccount />} /> 
       <Route path='/i' element={<SMS_View/>}/>
       <Route path='/s' element={<POC/>}/>
       <Route path="/refund" element={<CancellationRefundPolicy />} />
      <Route path="/*" element={<App />} />
    </Routes>
  </Router>,
  document.getElementById('root')
);
